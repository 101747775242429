import { Container, Flex, Text } from "flicket-ui";
import React from "react";
import { Icon, IconProps, Modal } from "~components/common";
import { useIsMobile } from "~hooks";

interface CheckoutBaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  columns: CheckoutBaseModalItem[];
  intro: string;
  icon?: IconProps["icon"];
  colour?: string;
  borderColour?: string;
}

export interface CheckoutBaseModalItem {
  iconName: IconProps["icon"];
  title: string;
  content: string;
}

export const CheckoutBaseModal = ({
  isOpen,
  onClose,
  columns,
  intro,
  icon = "assignment",
  colour,
  borderColour,
}: CheckoutBaseModalProps) => {
  const isMobile = useIsMobile();

  const Desktop = (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      height="auto"
      bg="transparent"
      aria-label="hold-order-dialog"
    >
      <Container
        px={2}
        innerProps={{
          w: "100%",
          maxW: "800px",
          mx: "auto" as any,
          p: { _: 2, md: 4 },
          border: `solid 5px ${borderColour ?? "#FFF"}`,
        }}
      >
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Icon
            icon={icon}
            fontSize={"150px" as any}
            pb={3}
            color={"pink" as any}
          />
          <Text fontWeight={"heavy"} mx={9} pb={3} color={colour as any}>
            {intro}
          </Text>

          <Flex flexDir={"row"}>
            {columns.map((column, index) => (
              <Flex
                flexDir="column"
                flex={"0 0 25%"}
                maxW={isMobile ? "100%" : "25%"}
                p={2}
                key={index}
              >
                <Icon icon={column.iconName} fontSize={9} />
                <Text mt={2} fontWeight={"heavy"} color={colour as any}>
                  {column.title}
                </Text>
                <Text mt={2}>{column.content}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Modal>
  );

  const Mobile = (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      height="100vh"
      bg="transparent"
      overflowY="auto"
    >
      <Container
        p={2}
        my={6}
        innerProps={{
          w: "100%",
          mx: "auto" as any,
          p: { _: 2, md: 4 },
        }}
        position={"relative"}
      >
        <Icon
          icon="close"
          position={"absolute"}
          right={"2rem"}
          fontSize={6}
          float="right"
          onClick={() => onClose()}
        />
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Icon icon={icon} fontSize={"150px" as any} pb={3} mt={5} />
          <Text fontWeight={"heavy"} mx={4} color={colour as any}>
            {intro}
          </Text>
          <Flex flexDir="column" mt={8} my={6}>
            {columns.map((column, index) => (
              <Flex
                flexDir="column"
                maxW={"80%"}
                key={index}
                mx={"auto" as any}
                mt={2}
              >
                <Icon icon={column.iconName} fontSize={9} />
                <Text mt={2} fontWeight={"heavy"} color={colour as any}>
                  {column.title}
                </Text>
                <Text mt={2} color={colour as any}>
                  {column.content}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Modal>
  );

  return isMobile ? Mobile : Desktop;
};
