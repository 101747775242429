import { createContext, useContext } from "react";

export const ModalContext = createContext<{
  close?: (...args: unknown[]) => unknown;
  rootRef: React.RefObject<HTMLDivElement>;
}>({ close: undefined, rootRef: undefined });

export function useModal() {
  const { close, rootRef } = useContext(ModalContext);

  return {
    close,
    rootRef,
  };
}
