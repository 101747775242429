import {
  FeeType,
  IntegrationType,
  PaymentProvider,
  OrderQuery,
  Integration,
  PaymentPlanMetadata,
} from "~graphql/sdk";

export type LineItem = OrderQuery["order"]["lineItems"]["edges"][number]["node"];

export enum CustomPaymentType {
  Comp = "comp",
  Paid = "paid",
  OnHold = "on-hold",
  Point = "point",
  Credit = "credits",
}

export type PaymentOptionType =
  | IntegrationType
  | CustomPaymentType
  | PaymentProvider;

export interface PaymentOption {
  id: string;
  value: string;
  label: string;
  type: PaymentOptionType;
  paymentMethod?: string;
  description?: string;
  order?: number;
  transactionFee?: number;
  transactionFeeType?: FeeType;
  transactionFee2?: number;
  transactionFeeType2?: FeeType;
  bnplEnabled?: boolean;
  paymentPlanMetadata?: PaymentPlanMetadata;
}
export interface BuyNowPayLaterConfig {
  terms: number;
  period: "week" | "fortnight" | "month";
  icon: "laybuy" | "afterpay";
  brandColour: string;
}

export type BNPLIntegrationType =
  | IntegrationType.PaymentAfterpay
  | IntegrationType.PaymentLaybuy;

export type BNPLIntegration = Omit<Integration, "createdAt" | "updatedAt"> & {
  type: BNPLIntegrationType;
};

export const isBNPLIntegrationType = (
  integrationType: IntegrationType
): integrationType is BNPLIntegrationType => {
  return (
    integrationType === IntegrationType.PaymentAfterpay ||
    integrationType === IntegrationType.PaymentLaybuy
  );
};

/* -------------------------------------------------------------------------- */
/*                              Pin Payment Types                             */
/* -------------------------------------------------------------------------- */

export interface HostedFieldsInstance {
  on: (event: string, cb: (event: any) => void) => void;
  tokenize: (
    data: {
      publishable_api_key: string;
      address_line1: string;
      address_line2: string;
      address_city: string;
      address_postcode: string;
      address_state: string;
      address_country: string;
    },
    cb: (
      err:
        | null
        | undefined
        | {
            error: string;
            error_description: string;
            messages: {
              code: string;
              message: string;
              param: string;
            }[];
          },
      response
    ) => void
  ) => void;
}

export interface PinPaymentsTokenizeResponse {
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string;
  address_postcode: string;
  address_state: string;
  customer_token: string | null;
  display_number: string;
  expiry_month: number;
  expiry_year: number;
  name: string;
  scheme: string;
  token: string;
}

export interface PinPaymentsTokenizeError {
  error: string;
  error_description: string;
  messages?: {
    code: string;
    param:
      | "name"
      | "number"
      | "cvc"
      | "expiry"
      | "address_line1"
      | "address_city"
      | "address_country";
    message: string;
  }[];
}
