import capitalize from "lodash/capitalize";
import React from "react";
import {
  BNPLIntegrationType,
  BuyNowPayLaterConfig,
} from "~features/checkout/checkout.types";
import { BNPL_CONFIG } from "~features/checkout/constants";
import { IntegrationType } from "~graphql/sdk";
import { hex2rgba } from "~lib/helpers/hex2rgba";
import { AfterPayNativeModal } from "./checkout.AfterPayNative.modal";
import {
  CheckoutBaseModal,
  CheckoutBaseModalItem,
} from "./checkout.base.modal";
import { IconProps } from "~components/common";

interface CheckoutBNPLModalProps {
  variant: BNPLIntegrationType;
  isOpen: boolean;
  onClose: () => void;
}

const icons: { [K in BuyNowPayLaterConfig["icon"]]: IconProps["icon"] }[] = [
  {
    laybuy: "laybuy_cursor",
    afterpay: "cursor",
  },
  {
    laybuy: "laybuy_lb_heart",
    afterpay: "afterpay-icon",
  },
  {
    laybuy: "laybuy_calendar",
    afterpay: "calendar",
  },
  {
    laybuy: "laybuy_sunglasses",
    afterpay: "sunglasses",
  },
];

const columns = (
  name: string,
  config: BuyNowPayLaterConfig
): CheckoutBaseModalItem[] => {
  return [
    {
      iconName: icons[0][config?.icon],
      title: `Pay by ${name}.`,
      content: `Proceed to checkout. Select ${name} as your payment method.`,
    },
    {
      iconName: icons[1][config?.icon],
      title: "Complete order.",
      content: "Log in or sign up. Complete your order in seconds.",
    },
    {
      iconName: icons[2][config?.icon],
      title: "Select schedule.",
      content:
        "Choose your payment day. View your schedule and select pay now.",
    },
    {
      iconName: icons[3][config?.icon],
      title: "You're done.",
      content: `Your items are on their way. Payments will be taken automatically each ${config?.period}.`,
    },
  ];
};

export const CheckoutBNPLModal = ({
  variant,
  isOpen,
  onClose,
}: CheckoutBNPLModalProps) => {
  const config = BNPL_CONFIG[variant];
  const bnplName = capitalize(config?.icon);
  const intro = `Deciding what to buy is hard, but paying for it doesn't have to be. ${bnplName} lets you receive the item now and spread the payment over ${config?.terms} ${config?.period}ly automatic instalments. Interest free!`;

  return (
    <CheckoutBaseModal
      isOpen={isOpen}
      onClose={onClose}
      columns={columns(bnplName, config)}
      intro={intro}
      borderColour={
        config?.brandColour ? hex2rgba(config?.brandColour, 1) : undefined
      }
      icon={config?.icon}
    />
  );
};

export const withVariantCheckoutBNPLModal = (
  variant: BNPLIntegrationType
) => () => {
  if (variant === IntegrationType.PaymentAfterpay) {
    return AfterPayNativeModal;
  }
  const Modal = (props: Omit<CheckoutBNPLModalProps, "variant">) => (
    <CheckoutBNPLModal variant={variant} {...props} />
  );
  Modal.displayName = `CheckoutBNPLModal(${variant})`;
  return Modal;
};
