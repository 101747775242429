import { Box } from "flicket-ui";
import { ReactNode, useState } from "react";
import {
  useIntersectionObserver,
  UseIntersectionObserverOptions,
} from "~hooks";

export type IStickySection = {
  children: ReactNode | ((props: { isSticky: boolean }) => ReactNode);
  options?: UseIntersectionObserverOptions;
  offset?: number;
};

export default function StickySection({
  children,
  options = {},
  offset = 0,
}: IStickySection) {
  const [isSticky, setIsSticky] = useState(false);

  const ref = useIntersectionObserver({
    threshold: 1,
    root: document,
    // This margin offset detects the intersection point
    rootMargin: `-${offset + 1}px 0px 0px 0px`,
    ...options,
    onChange(isIntersecting, entry) {
      const isInteresectingWithTop =
        entry.boundingClientRect.top < entry.intersectionRect?.top;
      setIsSticky(isInteresectingWithTop);
    },
  });

  if (!children) return null;

  return (
    <>
      <Box
        ref={ref}
        zIndex="sticky"
        css={`
          position: sticky;
          top: ${offset}px;
        `}
      >
        {typeof children === "function" ? children({ isSticky }) : children}
      </Box>
    </>
  );
}
