import { X } from "phosphor-react";
import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { pick } from "@styled-system/props";
import { Box, Flex, system, SystemProps, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";
import { ReactNode, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { ModalContext } from "./useModal";
import {
  modalBackdropMotion,
  modalContentMotion,
} from "~lib/helpers/animation/modal";
import Icon from "../Icon";
import StickySection from "../StickySection";
import { IStickySection } from "../StickySection/StickySection";

const MOBILE_BREAKPOINT = "sm";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<{
  $alignModalTop: boolean;
}>`
  background: rgb(242, 242, 242, 0.94);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* overflow: auto; */
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    align-items: ${(p) => (p.$alignModalTop ? " flex-start" : "center")};
    padding: ${(p) => p.theme.space[4]}px;
  }
`;

const DialogContent = styled(MotionDialog)<SystemProps>`
  padding: 0;
  width: 100%;
  margin: 0;
  position: relative;
  background: transparent;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    width: 800px;
  }

  && {
    ${system}
  }
`;

const ContentWrapper = styled.div<SystemProps & { $small?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  overflow: hidden;
  background-color: ${(p) => p.theme.colors.white};
  outline: none;
  width: 100%;
  height: 100dvh;

  ${(p) => (p.$small ? `padding: ${p.theme.space[3]}px;` : "")}

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    margin: 0;
    border-radius: ${(p) => p.theme.radii.md};
    box-shadow: ${(p) => p.theme.shadows.card};
    height: auto;
    max-height: 94dvh;
  }

  && {
    ${system}
  }
`;

export type ModalProps = SystemProps<{
  isOpen: boolean;
  close: (any) => void;
  ariaLabel?: string;
  children: ReactNode;
  hasDivider?: boolean;
  small?: boolean;
  alignModalTop?: boolean;
  dialogContentProps?: SystemProps;
  modalBaseProps?: SystemProps;
  clickOutsideToClose?: boolean;
  showCloseButton?: boolean;
  rootRef?: React.RefObject<HTMLDivElement>;
}>;

export const ModalBaseWrapper = ({
  children,
  ariaLabel = "modal",
  isOpen,
  close: closeOutside,
  small = false,
  alignModalTop = false,
  dialogContentProps = {},
  showCloseButton = true,
  rootRef,
  ...props
}: ModalProps) => {
  const { close } = useContext(ModalContext);

  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay
          {...modalBackdropMotion}
          onDismiss={closeOutside}
          $alignModalTop={alignModalTop}
        >
          <DialogContent
            ref={rootRef}
            {...modalContentMotion}
            aria-label={ariaLabel}
            width={small ? "470px" : undefined}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            {...pick(dialogContentProps)}
          >
            {showCloseButton && (
              <Flex
                borderRadius="full"
                background={{ _: "rgba(0,0,0,0.6)" }}
                position="absolute"
                right={{ _: 2 }}
                top={{ _: 2 }}
                zIndex={99999}
                w={{ _: 40 }}
                h={{ _: 40 }}
                variant="center"
                boxShadow="card"
                css={`
                  &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.8);
                  }
                `}
                onClick={() => {
                  close();
                }}
              >
                <Icon icon={<X size={20} />} color={{ _: "white" } as any} />
              </Flex>
            )}

            <ContentWrapper
              id="modal-content-wrapper"
              $small={small}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              {...pick(props)}
            >
              {children}
            </ContentWrapper>
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};

const Modal = ({
  children,
  ariaLabel,
  isOpen,
  close,
  clickOutsideToClose = false,
  showCloseButton = true,
  small,
  ...props
}: ModalProps) => {
  const rootRef = useRef(null);
  return (
    <ModalContext.Provider value={{ close, rootRef }}>
      <ModalBaseWrapper
        close={clickOutsideToClose ? close : noop}
        isOpen={isOpen}
        ariaLabel={ariaLabel}
        small={small}
        rootRef={rootRef}
        showCloseButton={showCloseButton}
        {...props}
      >
        {children}
      </ModalBaseWrapper>
    </ModalContext.Provider>
  );
};

function Header({
  children,
  onBack,
}: {
  children: ReactNode;
  onBack?: () => void;
  close?: () => void;
}) {
  const { close } = useContext(ModalContext);
  return (
    <Box
      py={2}
      pr={8}
      pl={onBack ? 8 : [3, 3, 4]}
      d="flex"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
      borderBottomColor="N200"
      borderBottomStyle="solid"
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderBottomWidth: "1px",
        lineHeight: 1,
      })}
    >
      {children && (
        <Text
          as="h5"
          variant="header.S"
          width={1}
          textAlign={onBack ? "center" : "left"}
        >
          {children}
        </Text>
      )}

      <Icon
        icon={<X size={20} />}
        onClick={close}
        w="40px"
        h="40px"
        position="absolute"
        right="19px"
        color="N800"
        zIndex={3}
      />
    </Box>
  );
}

function ModalStickySection({
  children,
}: {
  children: IStickySection["children"];
}) {
  const { rootRef } = useContext(ModalContext);

  if (!children) return null;

  return (
    <StickySection options={{ root: rootRef?.current }}>
      {children}
    </StickySection>
  );
}

function Content({
  children,
  ...props
}: { children: ReactNode; small?: boolean } & SystemProps) {
  return (
    <Box
      fontSize={4}
      flex={1}
      p={[3, 3, 4]}
      overflowY="auto"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      {children}
    </Box>
  );
}

// eslint-disable-next-line react/display-name
function Footer({ children, ...props }: { children: ReactNode } & SystemProps) {
  return (
    <Box
      py={2}
      px={[3, 3, 4]}
      zIndex={1}
      borderTopStyle="solid"
      borderTopColor="N200"
      backgroundColor={"white"}
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderTopWidth: "1px",
      })}
      {...pick(props)}
    >
      <Flex justifyContent="flex-end">{children}</Flex>
    </Box>
  );
}

Modal.Footer = Footer;
Modal.Header = Header;
Modal.StickySection = ModalStickySection;
Modal.Content = Content;

export default Modal;
