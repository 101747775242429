import React, { useEffect } from "react";
import { getAfterPayLocaleByCurrency } from "~config";
import { useOrganization } from "~hooks";

export const AfterPayNativeModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { organization } = useOrganization();

  useEffect(() => {
    if (isOpen) {
      onClose(); // hack so that the modal always thinks it's closed
      const afterPayWindow = window as AfterPayWindowType;
      afterPayWindow?.Afterpay?.launchModal({
        locale: getAfterPayLocaleByCurrency(organization?.currency),
        currency: organization?.currency,
      });
    }
  }, [isOpen]);

  return <></>;
};

type AfterPayWindowType = {
  Afterpay?: {
    launchModal: ({
      locale,
      currency,
    }: {
      locale: string;
      currency: string;
    }) => void;
  };
};
